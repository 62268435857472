import { useContext } from 'react';
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { GlobalContext } from '../../GlobalContext';

function Spinner() {
    const { showSpinner } = useContext(GlobalContext);

    return (
        <>
            <div style={{ zIndex: 999999 }}>
                <DotLoaderOverlay
                    loading={showSpinner}
                    overlayColor='rgba(0,0,0,0.5)'
                    message={(<><p style={{ color: 'white' }}>Procesando, por favor espere</p></>)}
                    zIndex={99999999}
                ></DotLoaderOverlay>
            </div>
        </>
    );
}

export default Spinner;
