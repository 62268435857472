import React, { useState } from 'react'
import parse from 'html-react-parser';

import HeaderBlog from './HeaderBlog'

function BlogDisplay(props) {
    const { posts } = props
    const [postHandle, setPostHandle] = useState("")
    const [isIndexBlog, setIsIndexBlog] = useState(true)

    function onclickShowPostList() {
        setIsIndexBlog(true)
    }

    function onclickShowPostDetail(handle) {
        setPostHandle(handle)
        setIsIndexBlog(false)
    }

    function PostList() {

        const postItem = (post) => {
            return (
                <div className="col-lg-4 col-md-6 col-sm-12 pb-4" key={post.id}>
                    <div className="post">

                        <h4>{post.titulo}</h4>
                        <small className="text-secondary">Fecha de Publicación {post.date}</small>
                        <p>{post.short}</p>

                        <div className="botonPost">
                            <button className="btn btn-pack" onClick={() => onclickShowPostDetail(post.handle)}> Leer mas </button>
                        </div>

                    </div>
                </div>
            )
        }

        return (
            <>
                <div className="container mt-5">
                    <div className="row">
                        {posts.map(post => postItem(post))}
                    </div>
                </div>

            </>
        )
    }

    function Post() {
        const post = posts.filter(postItem => postItem.handle === postHandle)[0]

        return (
            <>
                <div className="container my-5">

                    <h3 className="text-center">{post.titulo}</h3>
                    <p className="text-center text-secondary" style={{fontSize:10}}>Fecha de publicación {post.date}</p>

                    <div className="col-lg-10 col-md-12 col-sm-12 mx-auto postInText" style={{textAlign:'justify'}}>
                        <p>{parse(post.content)}</p>
                    </div>
                    
                    <div className="text-center mt-3">
                        <button className=" btn btn-pack" onClick={() => onclickShowPostList()}> Volver </button>
                    </div>
                    
                </div>
            </>
        )
    }

    function PostDetail() {
        return <Post />
    }

    return (
        <>
            <HeaderBlog />
            {isIndexBlog && <PostList />}
            {!isIndexBlog && <PostDetail />}
        </>
    )
}

export default BlogDisplay