import React from 'react'

function HeaderBlog() {
    return (
        <>
            <div className="container-fluid BlogBg">
                <div className="row" style={{ height: "200px" }}>
                    <div className="align-self-center text-center" >
                        <h1 className="display-3">Blog</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderBlog
