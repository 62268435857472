import React from "react"
import './Footerstyle.css'

function ContactFooter() {
    
    return(
        <div className="container-fluid CFooBg">
            <div className="container">
                <div className="row CFooH">
                    <h3 className="align-self-center">Atenderle con pasión y ofrecerle una buena atención y servicio es nuestra prioridad</h3>
                </div>
            </div>
        </div>
       
    )
}
export default ContactFooter