import React from "react"
import './Footerstyle.css'
import LogoFacebook from './img/facebook.svg'
import LogoInstagram from './img/instagram.svg'

function Footer() {
    const screenSM = window.innerWidth;
    return (
        <div className="container-fluid footer">

            <div className="col-6 mx-auto pt-5">
                <a href='https://www.facebook.com/Servicios-Integrados-PRO-103358528873843' target="_blank" rel="noreferrer">
                    <img src={LogoFacebook} alt="..." width={50} height={50} style={{ marginRight: "10px" }} loading="lazy"></img>
                </a>

                <a href='https://www.instagram.com/Servicios_Integrados_PRO' target="_blank" rel="noreferrer">
                    <img src={LogoInstagram} alt="..." width={50} height={50} style={{ marginLeft: "10px" }} loading="lazy"></img>
                </a>
            </div>
            <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
                <p><b>Servicios Integrados Pro</b>{screenSM <= 768 ? <br /> : " - "}Dirección: Costa Rica, Heredia<br />
                    Email:&nbsp;
                    <a
                        id='emailLinkFooter'
                        href="mailto:info@quoviscr.com"
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                        info@quoviscr.com
                    </a>
                    <br />
                    Central Telefónica:&nbsp;
                    <a
                        id='telfLinkFooter'
                        href="tel:+50640005252"
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                        +506 4000-5252
                    </a>
                    <br />
                    Whatsapp: &nbsp;
                    <a
                        id='whatsAppLink'
                        target='new'
                        onClick={() => { window.gtag_report_conversion(); }}
                        href='https://api.whatsapp.com/send/?phone=50640005252'
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                        +506 4000-5252
                    </a>
                    <br />
                    <a
                        href="/terms"
                        target='new'
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                        Términos y condiciones
                    </a>
                </p>
                <p><b>© 2023 Servicios Integrados Pro</b></p>
            </div>

        </div>
    )
}
export default Footer