import './menubar.css'
import { useEffect } from 'react'
import Logo from './Logo.png'
import React from "react"
import {NavLink} from "react-router-dom"

export default function MenuV2() {

    const siproFacturatorUrl = process.env.REACT_APP_SIPRO_FACTURATOR;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
 

    return (
        <nav className="navbar p-0 navbar-expand-lg navbar-light menubg sticky-top">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img loading="lazy" className="responsive-menu-logo mt-2" src={Logo} alt="logo"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/">Inicio</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#top" id="navbarDropdownMenuLink" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                Servicios
                            </a>
                            <ul className="dropdown-menu menuStyle" aria-labelledby="navbarDropdownMenuLink">
                                <li className="nav-item">
                                    <NavLink className="nav-link"  exact to="/facturaelectronica">Facturación Electrónica</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" exact to="/declaraciondeiva">Declaración de IVA</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/declaracionDeRenta">Declaración de renta</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/recepcionDeDocumentos">Recepción Automática de Documentos.</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/asistentedefacturacion">Asistente de Facturación</NavLink>    
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/quienes-somos">Quienes Somos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/planesyprecios">Planes y precios</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/blog">Blog</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/contacto">Contacto</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="btn-menuBlue" target="_blank" rel="noreferrer" href={siproFacturatorUrl}>Login
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}