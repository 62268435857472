import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Suspense, lazy } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './App.css';
import Blog from "./pages/blog-facturator/views/Blog";
import postData from "./pages/blog-facturator/data/blogData.json";
import { Toaster } from 'react-hot-toast';
import Spinner from "./components/spinner/Spinner"
import { GlobalContext } from "./GlobalContext";
import { useState } from "react";
import MenuV2 from "./components/menu/MenuV2";
import Whatsapp from "./components/whatsapp/Whatsapp";

function App() {
  const [showSpinner, setShowSpinner] = useState(false)
  const context = { showSpinner, setShowSpinner };

  const HomeV2 = lazy(() => import('./pages/home/HomeV2'));
  const FacturaElectronica = lazy(() => import('./pages/facturaElectronica/FacturaElectronica'));
  const AsistenteDeFacturacion = lazy(() => import('./pages/asistenteDeFacturacion/AsistenteDeFacturacion'));
  const DeclaracionDeRenta = lazy(() => import('./pages/declaracionDeRenta/DeclaracionDeRenta'));
  const Recepciondedocumentos = lazy(() => import('./pages/recepcionDeDocumentos/RecepcionDeDocumentos'));
  const DeclaracionDeIva = lazy(() => import('./pages/declaracionDeIva/DeclaracionDeIva'));
  const Home = lazy(() => import('./pages/home/Home'));
  const Services = lazy(() => import('./pages/services/Services'));
  const About = lazy(() => import('./pages/about/About'));
  const Contact = lazy(() => import('./pages/contact/Contact'));
  const Register = lazy(() => import('./pages/register/Register'));
  const Suggested = lazy(() => import('./pages/suggested/Suggested'));
  const Summary = lazy(() => import('./pages/summary/Summary'));
  const Result = lazy(() => import('./pages/result/Result'));
  const Terms = lazy(() => import('./pages/terms/Terms'));

  return (
    <Router>
      <MenuV2 />
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
        }}
      />
      <GlobalContext.Provider value={context}>
        <Spinner showSpinner={false}>
        </Spinner>
        <Switch>
          <Suspense fallback={''}>
            <Route exact path="/" component={HomeV2} />
            <Route exact path="/facturaelectronica" component={FacturaElectronica} />
            <Route exact path="/asistentedefacturacion" component={AsistenteDeFacturacion} />
            <Route exact path="/declaraciondeRenta" component={DeclaracionDeRenta} />
            <Route exact path="/recepciondedocumentos" component={Recepciondedocumentos} />
            <Route exact path="/declaraciondeiva" component={DeclaracionDeIva} />
            <Route exact path="/planesyprecios" component={Home} />
            <Route exact path="/blog">
              <Blog posts={postData} />
            </Route>
            <Route exact path="/servicios" component={Services} />
            <Route exact path="/quienes-somos" component={About} />
            <Route exact path="/contacto" component={Contact} />
            <Route exact path="/registro" component={Register} />
            <Route exact path="/suggested" component={Suggested} />
            <Route exact path="/resumen" component={Summary} />
            <Route path="/result" component={Result} />
            <Route path="/terms" component={Terms} />
          </Suspense>
        </Switch>
      </GlobalContext.Provider>
      <Whatsapp />
    </Router>
  );
}
export default App;
