import React from 'react'
import ContactFooter from '../../../components/footer/ContactFooter'
import Footer from '../../../components/footer/Footer'
import BlogDisplay from './BlogDisplay'

function Blog(props) {

    const {posts} = props

    return (
        <>
            <BlogDisplay posts={posts} isIndexBlog={true}/>
            <ContactFooter/>
            <Footer/>
        </>
    )
}

export default Blog
