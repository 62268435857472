import React from "react"
import ReactWhatsappButton from "react-whatsapp-button";

function Whatsapp() {

    return (
        <div className="whatsapp">

            <span className="HelloWhatsapp">
                <p className="HelloCenter m-0">¿Tiene dudas? <br /> ¡Escríbanos! </p>
            </span>
            <span className="circulo1"></span>
            <span className="circulo2"></span>

            <ReactWhatsappButton 
                phoneNumber="40005252" 
                countryCode="506"
                animated="true" 
                callback={() => { window.gtag_report_conversion();}}>
            </ReactWhatsappButton>

        </div>
    )
}
export default Whatsapp